.formcontainer {
  display: grid;
  grid-template-columns: 80%;
  justify-content: flex-end;
  margin-top: 3rem;
}

.middle {
  display: flex;
  justify-content: center;
  margin:1rem 0;
}
.excel {
  margin: 1rem;
}
.excelError{
 color: red;
}

.tableContainer {
  overflow-x: auto;
}

table {
  width: 90%;
  margin: 0 auto;
}

table,
th,
td {
  /* border: 1px solid rgb(27, 27, 27); */
  border: none !important;
  text-align: center;
  padding: 0 0.2rem 0 0.2rem;
}

th {
  background-color: #8383fa !important;
  user-select: none;
  color: white !important;
}

td {
  color: black !important;
  font-size: 18px;
}

.sl-no {
  text-align: center;
}

input {
  height: 2rem;
  width: auto;
  outline: none !important;
}

.table-head {
  line-height: 2rem;
}

/* Table Body Border */
.table-data1 {
  border-top: 1px solid black !important;
  border-left: 1px solid black !important;
  border-bottom: 1px solid black !important;
}

.table-data2 {
  border-top: 1px solid black !important;
  border-bottom: 1px solid black !important;
  text-align: center;
}

.table-data3 {
  border-top: 1px solid black !important;
  border-bottom: 1px solid black !important;
  text-align: center;
}

.table-data4 {
  border-top: 1px solid black !important;
  border-right: 1px solid black !important;
  border-bottom: 1px solid black !important;
  text-align: center;
}

.head-cell:first-child {
  border-top-left-radius: 0.5rem;
}

.head-cell:last-child {
  border-top-right-radius: 0.5rem;
}

.input-FielD {
  font-size: 1.5rem !important;
  font-weight: bold !important;
  text-align: center;
}

.alignAt-right {
  margin: 0 auto;
  width: 90% !important;
}

.alignAt-right button {
  border-radius: 1rem !important;
}

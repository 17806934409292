.formcontainer {
  display: grid;
  grid-template-columns: 95%;
  justify-content: flex-end;
  margin-top: 3rem;
}
.formcontainer input,
.MuiInput-underline:after {
  font-size: 1.2rem;
  border-bottom: 1px solid #313c4e;
}
.middle {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}
.excel {
  margin: 1 auto;
  overflow: auto;
}
.excelError {
  color: red;
}

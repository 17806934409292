.ant-input-affix-wrapper{
    padding: 0px 11px;
}

.ant-input-search-button {
    height: 34px;
}

.export-btn{
    background-color: #3f51b5 !important;
    color:#fff;
    cursor: pointer;
    padding: 0.4rem 1rem;
        border-radius: 3px;
}
.export-btn:hover{
    background-color: #5866b3 !important;
}
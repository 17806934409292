.tablecontainer {
  display: flex;
  overflow: auto;
}

table,
th,
td {
  border: 1px solid rgb(27, 27, 27);
  text-align: center;
  padding: 0 0.2rem 0 0.2rem;
}

th {
  background-color: rgb(199, 199, 199);
  user-select: none;
}

input {
  height: 2rem;
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
}

table {
  width: 90%;
}

.print {
  border-radius: "15px";
  padding: "0.5rem";
  color: "white";
  background-color: "linear-gradient(to right top, #f3e879, #fcc866, #fea85f, #fa8962, #ed6b6b)";
}

.newformcontainer {
  display: flex  !important;
  width: 100%;
  flex-direction: column;
  align-items: center;
}
.form-main {
  width: 100%;
}
.field-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.text-field {
  width: 45% !important;
  margin: 1rem !important;
}
.text-fields {
  width: 28% !important;
  margin: 1rem !important;
}

.MuiFormControl-root {
  width: 45% !important;
}
.MuiFormLabel-root {
  color: rgb(104, 104, 104) !important;
  /* font-weight: 500 !important; */
  font-size: 15px !important;
  }

 
  /* .MuiInputBase-root {
  color: black !important;
  font-weight: bold !important;
  font-size: 1.3rem !important;
  width:100% !important;
} */



input[type="text"] {
  color: black !important;
  /* font-weight: bold !important; */
  font-size: 15px !important;
} 
 .MuiInput-underline:before {
  border-bottom: 0.1rem solid rgb(20, 19, 19) !important;
}

.MuiFormControl-fullWidth {
  width: 100% !important;
}

/* Media Queries for form Page */
@media only screen and (max-width: 890px) {
  /* .form-main {
    width: 100% !important;
  } */

  .text-field{
    width: 40% !important;
  }
  .text-fields{
    width: 20% !important;
  }
}


@media only screen and (max-width: 648px) {
  
}
@media only screen and (max-width: 400px) {
  .text-field{
    width: 60% !important;
  }
  .text-fields{
    width: 60% !important;
  }
}


